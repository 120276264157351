<template>
    <v-container fluid>
        <PageHeaderSlot showBackButton :isLoading="$store.getters.isLoading"> </PageHeaderSlot>
        <v-row>
            <v-col cols="12">
                <v-form ref="dataForm" @submit.prevent="save">
                    <v-card class="mb-8">
                        <v-card-text>
                            <v-row class="ma-0">
                                <v-col cols="12" md="6" lg="4">
                                    <FormSelect
                                        label="預約類型"
                                        :fieldValue.sync="formData.category"
                                        :dispatchUpdateOnChange="dispatchUpdateOnChange"
                                        :options="$appointmentTypeOptions"
                                    ></FormSelect>
                                </v-col>
                                <v-col cols="12" md="6" lg="4">
                                    <FormDatePicker
                                        label="預約日期"
                                        :fieldValue.sync="formData.date"
                                        :dispatchUpdateOnChange="dispatchUpdateOnChange"
                                    ></FormDatePicker>
                                </v-col>
                                <v-col cols="12" md="6" lg="4">
                                    <FormSelect
                                        label="預約時間"
                                        :fieldValue.sync="formData.time"
                                        :dispatchUpdateOnChange="dispatchUpdateOnChange"
                                        :options="appointmentTimeOptions"
                                    ></FormSelect>
                                </v-col>
                                <v-col cols="12" md="6" lg="4">
                                    <FormInput
                                        label="稱呼"
                                        :fieldValue.sync="formData.name"
                                        :dispatchUpdateOnChange="dispatchUpdateOnChange"
                                    ></FormInput>
                                </v-col>
                                <v-col cols="12" md="6" lg="4">
                                    <FormInput
                                        label="電郵"
                                        type="email"
                                        :fieldValue.sync="formData.email"
                                        :dispatchUpdateOnChange="dispatchUpdateOnChange"
                                    ></FormInput>
                                </v-col>
                                <v-col cols="12" md="6" lg="4">
                                    <FormInput
                                        label="電話"
                                        type="tel"
                                        :fieldValue.sync="formData.phone"
                                        :dispatchUpdateOnChange="dispatchUpdateOnChange"
                                    ></FormInput>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>

                    <v-btn depressed color="primary accent-4" class="white--text" type="submit"
                        :loading="$store.getters.isLoading"> {{ $validate.DataValid(id) ? '儲存' : '新增'}} </v-btn>
                </v-form>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import { mapActions } from 'vuex';
import FormInput from '@/components/form/FormInput.vue';
import FormDatePicker from '@/components/form/FormDatePicker.vue';
import FormSelect from '@/components/form/FormSelect.vue';

export default {
    name: 'AppointmentDetails',
    components: {
        FormInput,
        FormDatePicker,
        FormSelect
    },
    computed: {
        id() {
            const id = parseInt(this.$route.params.id);
            if (!isNaN(id)) {
                return id;
            }

            return null;
        },
        dispatchUpdateOnChange() {
            return true;
        },
    },
    data: () => ({
        rowCount: 0,
        formData: {
            category: '',
            date: '',
            time: '',
            name: '',
            email: '',
            phone: '',
        },

        appointmentTimeOptions: [
            { text: '11:00', value: '11:00' },
            { text: '12:00', value: '12:00' },
            { text: '13:00', value: '13:00' },
            { text: '14:00', value: '14:00' },
            { text: '15:00', value: '15:00' },
            { text: '16:00', value: '16:00' },
            { text: '17:00', value: '17:00' },
            { text: '18:00', value: '18:00' }
        ],
    }),

    methods: {
        ...mapActions(['setDialogMessage', 'setShowDialog', 'setLeaveDialogLink', 'setShowLeaveDialog']),
        async getAppointmentDetails() {
            try {
                const data = await this.$Fetcher.GetAppointmentById(this.id);
                this.$set(this.formData, 'category', data.category);
                this.$set(this.formData, 'date', data.date);
                this.$set(this.formData, 'time', data.time);
                this.$set(this.formData, 'name', data.name);
                this.$set(this.formData, 'email', data.email);
                this.$set(this.formData, 'phone', data.phone);
            } catch (err) {
                this.$common.error(err);
                this.setDialogMessage({
                    title: "找不到資料",
                    message: "找不到資料，請確保網址正確。",
                    isError: true,
                    returnLink: { name: 'AppointmentList' },
                });
                this.setShowDialog(true);
            } finally {
                this.$store.dispatch('toggleLoadingPage', false);
            }
        },

        async save() {
            if (this.$store.getters.isLoading) {
                this.$store.dispatch('toggleAlertMessage', {
                    show: true,
                    message: 'processing',
                    type: 'error',
                    refresh: false,
                    redirect: ''
                });
                return;
            }

            this.$store.dispatch('setLoading', true);
            const valid = await this.$refs['dataForm'].validate();
            if (!valid) {
                this.$store.dispatch('setLoading', false);
                this.$store.dispatch('toggleAlertMessage', {
                    show: true,
                    message: 'required',
                    type: 'error',
                    refresh: false,
                    redirect: ''
                });
                return;
            }

            const payload = {
                category: this.formData.category,
                date: this.formData.date,
                time: this.formData.time,
                name: this.formData.name,
                email: this.formData.email,
                phone: this.formData.phone,
            }
          
            try {
                await this.$Fetcher.UpdateAppointment(this.id, payload);
                this.$store.dispatch('setDataIsUpdated', false);
                this.$store.dispatch('toggleAlertMessage', {
                    show: true,
                    message: '更改成功',
                    type: 'success',
                    refresh: true,
                    redirect: ''
                });

            } catch (err) {
                this.setDialogMessage({
                    title: "更改失敗",
                    message: err,
                    isError: true,
                    returnLink: null,
                });
                this.setShowDialog(true);
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },
    },
    async created() {
        this.$store.dispatch('toggleLoadingPage', true);
        this.getAppointmentDetails();
    },

    // ------ navigation guard ------
    beforeRouteLeave(to, from, next) {
        if (this.$store.getters.isLoading) {
            this.$store.dispatch('toggleAlertMessage', {
                show: true,
                message: 'processing',
                type: 'error',
                refresh: false,
                redirect: ''
            });
            next(false);
        } else if (this.$store.getters.dataIsUpdated) {
            this.setLeaveDialogLink({ name: to.name });
            this.setShowLeaveDialog(true);
            next(false);
        } else {
            next();
        }
    },
}
</script>
  